import { styleSheet } from './styles';
import { css } from 'aphrodite';
import clsx from 'clsx';
import { useState, useEffect } from 'react';

export enum ECompactLogoIcon2023Type {
	Aqua = '#70ede3',
	Default = '#00CCFF',
	White = '#fff',
}
interface IProps {
	className?: string;
	height?: number;
	loading?: boolean;
	width?: number;
	type?: ECompactLogoIcon2023Type;
}

export const CompactLogoIcon2023 = ({
	className,
	height = 40,
	loading = false,
	type = ECompactLogoIcon2023Type.Default,
	width = 40,
}: IProps) => {
	const [isHovering, setIsHovering] = useState<boolean>(false);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const IS_BLUE_BACKGROUND = type === ECompactLogoIcon2023Type.Default || type === ECompactLogoIcon2023Type.White;

	useEffect(() => {
		if (loading && !isLoaded) {
			setTimeout(() => {
				setIsLoaded(true);
			}, 1000);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			className={`${className ?? ''}`}
			onMouseOver={() => setIsHovering(true)}
			onMouseOut={() => setIsHovering(false)}
		>
			{loading && <title>Loading...</title>}
			<mask
				id='mask0_0_2662'
				style={{ maskType: 'luminance' }}
				maskUnits='userSpaceOnUse'
				x='0'
				y='11'
				width='40'
				height='29'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M15.8503 11.3203L0 22.6422L23.7739 39.6226L39.6226 28.3023L15.8503 11.3203V11.3203Z'
					fill='#fff'
				/>
			</mask>
			{
				<g mask='url(#mask0_0_2662)'>
					{/* Shape */}
					<path
						d='M15.8503 11.3203L0 22.6422L23.7739 39.6226L39.6226 28.3023L15.8503 11.3203V11.3203Z'
						fill={IS_BLUE_BACKGROUND ? '#0066FF' : '#1d9fc8'}
					/>
					{/* Shades */}
					<path
						d='M23.7739 37.4956C21.8504 37.4956 20.005 36.9052 18.4374 35.7876L-5.3395 18.8026C-7.75248 17.0751 -9.18945 14.2796 -9.18945 11.3203C-9.18945 8.36106 -7.75248 5.56553 -5.34701 3.84405L10.5093 -7.48229C12.0784 -8.6029 13.9238 -9.19325 15.8487 -9.19325C17.7737 -9.19325 19.6176 -8.6029 21.1852 -7.48529L44.9621 9.49971C47.3751 11.2257 48.812 14.0212 48.812 16.982C48.812 19.9428 47.3751 22.7368 44.9696 24.4583L29.1133 35.7846C27.5442 36.9052 25.6988 37.4956 23.7739 37.4956Z'
						fill={IS_BLUE_BACKGROUND ? '#0066FF' : '#1d9fc8'}
					/>
					<path
						d='M23.7739 37.3559C21.8789 37.3559 20.0621 36.7745 18.5185 35.6735L-5.25842 18.69C-7.63535 16.988 -9.0498 14.236 -9.0498 11.3218C-9.0498 8.40763 -7.63535 5.65416 -5.26592 3.95821L10.5903 -7.36812C12.1354 -8.47071 13.9538 -9.05355 15.8487 -9.05355C17.7437 -9.05355 19.5605 -8.47221 21.1041 -7.37112L44.881 9.61387C47.2564 11.3128 48.6724 14.0663 48.6724 16.982C48.6724 19.8977 47.2579 22.6497 44.8885 24.3456L29.0323 35.672C27.4872 36.7745 25.6688 37.3574 23.7739 37.3574V37.3559Z'
						fill={IS_BLUE_BACKGROUND ? '#0065FD' : '#1d9ec7'}
					/>
					<path
						d='M23.7739 37.2162C21.909 37.2162 20.1191 36.6439 18.5996 35.5593L-5.17733 18.5758C-7.51673 16.9009 -8.91016 14.191 -8.91016 11.3203C-8.91016 8.44969 -7.51673 5.74129 -5.18484 4.07088L10.6714 -7.25396C12.1925 -8.34002 13.9823 -8.91385 15.8487 -8.91385C17.7152 -8.91385 19.5035 -8.34152 21.023 -7.25696L44.7999 9.72803C47.1393 11.4014 48.5328 14.1114 48.5328 16.9835C48.5328 19.8556 47.1393 22.5625 44.8074 24.233L28.9512 35.5593C27.4301 36.6454 25.6403 37.2192 23.7739 37.2192V37.2162Z'
						fill={IS_BLUE_BACKGROUND ? '#0065FB' : '#1d9ec7'}
					/>
					<path
						d='M23.7739 37.0765C21.9375 37.0765 20.1762 36.5132 18.6806 35.4451L-5.09624 18.4616C-7.3996 16.8123 -8.77051 14.1459 -8.77051 11.3203C-8.77051 8.49476 -7.3996 5.82841 -5.10375 4.18504L10.7525 -7.14129C12.2496 -8.21083 14.0109 -8.77415 15.8487 -8.77415C17.6866 -8.77415 19.4464 -8.21083 20.942 -7.1443L44.7189 9.8407C47.0207 11.4871 48.3931 14.1564 48.3931 16.982C48.3931 19.8076 47.0222 22.4739 44.7264 24.1173L28.8701 35.4436C27.3731 36.5132 25.6118 37.0765 23.7739 37.0765Z'
						fill={IS_BLUE_BACKGROUND ? '#0064F9' : '#1d9dc5'}
					/>
					<path
						d='M23.7739 36.9368C21.966 36.9368 20.2332 36.3825 18.7617 35.3325L-5.01515 18.349C-7.28248 16.7266 -8.63086 14.1008 -8.63086 11.3218C-8.63086 8.54283 -7.28248 5.91704 -5.02266 4.29921L10.8336 -7.02713C12.3081 -8.07864 14.0409 -8.63595 15.8503 -8.63595C17.6596 -8.63595 19.3909 -8.08165 20.8624 -7.03163L44.6393 9.95186C46.9051 11.5727 48.255 14.1985 48.255 16.979C48.255 19.7595 46.9066 22.3838 44.6468 24.0016L28.7905 35.328C27.3175 36.3795 25.5832 36.9353 23.7754 36.9353L23.7739 36.9368Z'
						fill={IS_BLUE_BACKGROUND ? '#0063F7' : '#1c9cc4'}
					/>
					<path
						d='M23.7739 36.7971C21.9961 36.7971 20.2918 36.2518 18.8428 35.2183L-4.93406 18.2348C-7.16385 16.638 -8.49121 14.0558 -8.49121 11.3218C-8.49121 8.58789 -7.16385 6.00417 -4.94157 4.41337L10.9162 -6.91296C12.3652 -7.94795 14.0709 -8.49474 15.8503 -8.49474C17.6296 -8.49474 19.3338 -7.94946 20.7813 -6.91597L44.5582 10.0675C46.788 11.6628 48.1154 14.2451 48.1154 16.9805C48.1154 19.7159 46.788 22.2982 44.5657 23.889L28.7094 35.2153C27.2605 36.2503 25.5547 36.7971 23.7754 36.7971H23.7739Z'
						fill={IS_BLUE_BACKGROUND ? '#0063F6' : '#1c9bc2'}
					/>
					<path
						d='M23.7739 36.6574C22.0246 36.6574 20.3489 36.1211 18.9239 35.1041L-4.85297 18.1206C-7.04672 16.5509 -8.35156 14.0107 -8.35156 11.3203C-8.35156 8.62995 -7.04672 6.08979 -4.85898 4.52453L10.9973 -6.8003C12.4222 -7.81877 14.101 -8.35504 15.8503 -8.35504C17.5996 -8.35504 19.2753 -7.81877 20.7002 -6.8018L44.4771 10.1817C46.6694 11.75 47.9757 14.2901 47.9757 16.982C47.9757 19.6739 46.6709 22.2125 44.4831 23.7778L28.6269 35.1041C27.2004 36.1226 25.5232 36.6589 23.7739 36.6589V36.6574Z'
						fill={IS_BLUE_BACKGROUND ? '#0062F4' : '#1c9ac1'}
					/>
					<path
						d='M23.7739 36.5177C22.0546 36.5177 20.4059 35.9904 19.005 34.9915L-4.77189 18.0065C-6.9281 16.4622 -8.21191 13.9656 -8.21191 11.3203C-8.21191 8.67502 -6.9281 6.17842 -4.77789 4.6387L11.0784 -6.68613C12.4808 -7.68658 14.1295 -8.21534 15.8503 -8.21534C17.571 -8.21534 19.2182 -7.68808 20.6192 -6.68914L44.396 10.2944C46.5523 11.8371 47.8361 14.3352 47.8361 16.9805C47.8361 19.6258 46.5523 22.1224 44.4021 23.6621L28.5458 34.9885C27.1433 35.9889 25.4947 36.5177 23.7739 36.5177Z'
						fill={IS_BLUE_BACKGROUND ? '#0061F2' : '#1c99bf'}
					/>
					<path
						d='M23.7739 36.378C22.0832 36.378 20.463 35.8597 19.0861 34.8773L-4.6908 17.8938C-6.81097 16.3766 -8.07227 13.9206 -8.07227 11.3218C-8.07227 8.72309 -6.81097 6.26705 -4.6968 4.75436L11.1595 -6.57197C12.5379 -7.55589 14.1595 -8.07564 15.8503 -8.07564C17.541 -8.07564 19.1612 -7.55739 20.5381 -6.57497L44.315 10.4085C46.4336 11.9242 47.6964 14.3802 47.6964 16.9805C47.6964 19.5807 46.4351 22.0353 44.321 23.548L28.4647 34.8728C27.0863 35.8567 25.4646 36.3765 23.7739 36.3765V36.378Z'
						fill={IS_BLUE_BACKGROUND ? '#0061F0' : '#1c98be'}
					/>
					<path
						d='M23.7739 36.2383C22.1132 36.2383 20.5201 35.729 19.1672 34.7631L-4.60971 17.7796C-6.69234 16.288 -7.93262 13.8755 -7.93262 11.3203C-7.93262 8.76515 -6.69235 6.35267 -4.61572 4.86553L11.2405 -6.45931C12.5949 -7.4267 14.1881 -7.93594 15.8503 -7.93594C17.5125 -7.93594 19.1041 -7.4267 20.457 -6.46081L44.2339 10.5227C46.3165 12.0128 47.5568 14.4253 47.5568 16.982C47.5568 19.5387 46.3165 21.9497 44.2399 23.4368L28.3851 34.7616C27.0307 35.729 25.4376 36.2398 23.7754 36.2398L23.7739 36.2383Z'
						fill={IS_BLUE_BACKGROUND ? '#0060EE' : '#1b97bd'}
					/>
					<path
						d='M23.7739 36.0986C22.1417 36.0986 20.5771 35.5968 19.2482 34.649L-4.52716 17.6655C-6.57375 16.2009 -7.7915 13.8305 -7.7915 11.3203C-7.7915 8.81022 -6.57376 6.4398 -4.53316 4.97969L11.3216 -6.34514C12.652 -7.29451 14.2181 -7.79623 15.8502 -7.79623C17.4824 -7.79623 19.047 -7.29451 20.3759 -6.34815L44.1528 10.6353C46.1979 12.0985 47.4171 14.4704 47.4171 16.9805C47.4171 19.4906 46.1994 21.861 44.1588 23.3211L28.304 34.646C26.9737 35.5953 25.4075 36.0971 23.7754 36.0971L23.7739 36.0986Z'
						fill={IS_BLUE_BACKGROUND ? '#0060EC' : '#1b95bb'}
					/>
					<path
						d='M23.7739 35.9589C22.1717 35.9589 20.6342 35.4661 19.3293 34.5363L-4.44607 17.5528C-6.45663 16.1137 -7.65186 13.7869 -7.65186 11.3218C-7.65186 8.85677 -6.45513 6.52842 -4.45207 5.09535L11.4027 -6.23098C12.709 -7.16383 14.2466 -7.65654 15.8503 -7.65654C17.4539 -7.65654 18.99 -7.16383 20.2948 -6.23399L44.0717 10.7495C46.0808 12.1871 47.2775 14.5154 47.2775 16.9805C47.2775 19.4455 46.0808 21.7739 44.0777 23.207L28.2229 34.5318C26.9166 35.4646 25.379 35.9574 23.7754 35.9574L23.7739 35.9589Z'
						fill={IS_BLUE_BACKGROUND ? '#005FEA' : '#1b94ba'}
					/>
					<path
						d='M23.7739 35.8192C22.2003 35.8192 20.6927 35.3355 19.4104 34.4221L-4.36498 17.4387C-6.338 16.0266 -7.51221 13.7403 -7.51221 11.3203C-7.51221 8.90035 -6.338 6.61555 -4.37099 5.20652L11.4838 -6.11831C12.7661 -7.03464 14.2751 -7.51833 15.8503 -7.51833C17.4254 -7.51833 18.9314 -7.03464 20.2137 -6.12132L43.9906 10.8622C45.9636 12.2727 47.1378 14.559 47.1378 16.9805C47.1378 19.402 45.9636 21.6853 43.9966 23.0943L28.1419 34.4191C26.8595 35.3355 25.349 35.8192 23.7754 35.8192H23.7739Z'
						fill={IS_BLUE_BACKGROUND ? '#005EE8' : '#1b93b8'}
					/>
					<path
						d='M23.7739 35.6795C22.2303 35.6795 20.7498 35.2048 19.4915 34.308L-4.28389 17.3245C-6.22088 15.938 -7.37256 13.6953 -7.37256 11.3203C-7.37256 8.9454 -6.21938 6.70267 -4.2899 5.32068L11.5649 -6.00416C12.8232 -6.90245 14.3052 -7.37714 15.8503 -7.37714C17.3953 -7.37714 18.8744 -6.90245 20.1327 -6.00566L43.9095 10.9778C45.845 12.3628 46.9982 14.6056 46.9982 16.982C46.9982 19.3584 45.845 21.5997 43.9156 22.9816L28.0608 34.3065C26.8025 35.2048 25.3205 35.6795 23.7754 35.6795H23.7739Z'
						fill={IS_BLUE_BACKGROUND ? '#005EE6' : '#1b92b7'}
					/>
					<path
						d='M23.7739 35.5398C22.2589 35.5398 20.8069 35.0741 19.5726 34.1953L-4.20427 17.2118C-6.10372 15.8524 -7.23438 13.6517 -7.23438 11.3218C-7.23438 8.99197 -6.10372 6.7913 -4.20877 5.43634L11.646 -5.88999C12.8803 -6.77176 14.3337 -7.23743 15.8503 -7.23743C17.3669 -7.23743 18.8173 -6.77176 20.0516 -5.893L43.8285 11.0905C45.728 12.4485 46.8586 14.6506 46.8586 16.9805C46.8586 19.3104 45.7279 21.511 43.833 22.866L27.9782 34.1908C26.7425 35.0726 25.2905 35.5383 23.7739 35.5383V35.5398Z'
						fill={IS_BLUE_BACKGROUND ? '#005DE5' : '#1a91b5'}
					/>
					<path
						d='M23.7739 35.4001C22.2889 35.4001 20.864 34.9434 19.6537 34.0812L-4.12318 17.0977C-5.98659 15.7637 -7.09473 13.6066 -7.09473 11.3218C-7.09473 9.03704 -5.98509 6.87842 -4.12768 5.54901L11.7271 -5.77583C12.9388 -6.64107 14.3638 -7.09773 15.8503 -7.09773C17.3368 -7.09773 18.7603 -6.64107 19.9705 -5.77883L43.7474 11.2047C45.6093 12.5371 46.719 14.6957 46.719 16.9805C46.719 19.2653 45.6093 21.4239 43.7519 22.7533L27.8972 34.0781C26.6854 34.9434 25.2605 35.4001 23.7739 35.4001Z'
						fill={IS_BLUE_BACKGROUND ? '#005CE3' : '#1a90b4'}
					/>
					<path
						d='M23.7739 35.2604C22.3174 35.2604 20.921 34.8127 19.7348 33.967L-4.04209 16.9835C-5.86796 15.6766 -6.95508 13.5601 -6.95508 11.3203C-6.95508 9.0806 -5.86796 6.96555 -4.04659 5.66167L11.8082 -5.66316C12.9959 -6.51039 14.3923 -6.95803 15.8503 -6.95803C17.3083 -6.95803 18.7032 -6.51039 19.8895 -5.66467L43.6663 11.3188C45.4922 12.6242 46.5793 14.7408 46.5793 16.982C46.5793 19.2232 45.4922 21.3368 43.6708 22.6407L27.8161 33.9655C26.6284 34.8127 25.2319 35.2604 23.7739 35.2604V35.2604Z'
						fill={IS_BLUE_BACKGROUND ? '#005CE1' : '#1a8fb3'}
					/>
					<path
						d='M23.7737 35.1207C22.3473 35.1207 20.9779 34.682 19.8157 33.8528L-3.96121 16.8693C-5.75105 15.588 -6.81714 13.515 -6.81714 11.3203C-6.81714 9.12566 -5.75255 7.05268 -3.96722 5.77583L11.889 -5.549C13.0527 -6.3797 14.4221 -6.81833 15.8501 -6.81833C17.2781 -6.81833 18.646 -6.3797 19.8082 -5.5505L43.5851 11.433C45.3749 12.7128 46.441 14.7858 46.441 16.982C46.441 19.1782 45.3764 21.2496 43.5911 22.5265L27.7363 33.8513C26.5726 34.682 25.2032 35.1207 23.7752 35.1207H23.7737Z'
						fill={IS_BLUE_BACKGROUND ? '#005BDF' : '#1a8eb1'}
					/>
					<path
						d='M23.7737 34.981C22.3758 34.981 21.0349 34.5513 19.8968 33.7402L-3.88012 16.7567C-5.63392 15.5024 -6.67749 13.4714 -6.67749 11.3218C-6.67749 9.17223 -5.63392 7.1413 -3.88613 5.89L11.9701 -5.43483C13.1098 -6.24901 14.4507 -6.67863 15.8486 -6.67863C17.2465 -6.67863 18.5874 -6.24901 19.7256 -5.43784L43.5025 11.5457C45.2548 12.8 46.2999 14.8309 46.2999 16.9805C46.2999 19.1301 45.2563 21.161 43.5085 22.4123L27.6537 33.7372C26.5141 34.5513 25.1732 34.981 23.7752 34.981H23.7737Z'
						fill={IS_BLUE_BACKGROUND ? '#005ADD' : '#1a8db0'}
					/>
					<path
						d='M23.7737 34.8413C22.4058 34.8413 21.0935 34.4206 19.9779 33.626L-3.79903 16.6425C-5.51529 15.4137 -6.53784 13.4264 -6.53784 11.3203C-6.53784 9.21429 -5.51679 7.22693 -3.80504 6.00266L12.0512 -5.32217C13.1669 -6.11832 14.4807 -6.53893 15.8486 -6.53893C17.2165 -6.53893 18.5289 -6.11832 19.6445 -5.32367L43.4214 11.6598C45.1377 12.8871 46.1602 14.876 46.1602 16.982C46.1602 19.088 45.1392 21.0754 43.4274 22.2997L27.5726 33.6245C26.457 34.4206 25.1432 34.8413 23.7753 34.8413H23.7737Z'
						fill={IS_BLUE_BACKGROUND ? '#005ADB' : '#198cae'}
					/>
					<path
						d='M23.7738 34.7015C22.4344 34.7015 21.1506 34.29 20.0589 33.5118L-3.71794 16.5283C-5.39817 15.3266 -6.39819 13.3798 -6.39819 11.3203C-6.39819 9.26086 -5.39817 7.31405 -3.72395 6.11532L12.1323 -5.20801C13.2239 -5.98763 14.5093 -6.39922 15.8486 -6.39922C17.188 -6.39922 18.4718 -5.98763 19.5634 -5.20951L43.3403 11.774C45.019 12.9757 46.0206 14.921 46.0206 16.982C46.0206 19.043 45.0205 20.9883 43.3463 22.187L27.4916 33.5118C26.3999 34.2915 25.1146 34.7031 23.7753 34.7031L23.7738 34.7015Z'
						fill={IS_BLUE_BACKGROUND ? '#0059D9' : '#198bad'}
					/>
					<path
						d='M23.7738 34.5618C22.4644 34.5618 21.2076 34.1593 20.14 33.3992L-3.63686 16.4157C-5.27954 15.2395 -6.25854 13.3362 -6.25854 11.3218C-6.25854 9.30743 -5.28104 7.40418 -3.64286 6.23099L12.2134 -5.09384C13.281 -5.85694 14.5378 -6.25952 15.8486 -6.25952C17.1595 -6.25952 18.4148 -5.85694 19.4824 -5.09684L43.2592 11.8866C44.9019 13.0613 45.8809 14.9646 45.8809 16.9805C45.8809 18.9964 44.9034 20.8981 43.2653 22.0713L27.4105 33.3962C26.3429 34.1593 25.0846 34.5618 23.7753 34.5618H23.7738Z'
						fill={IS_BLUE_BACKGROUND ? '#0058D7' : '#198aab'}
					/>
					<path
						d='M23.7738 34.4221C22.493 34.4221 21.2647 34.0286 20.2211 33.285L-3.55577 16.3015C-5.16242 15.1509 -6.1189 13.2912 -6.1189 11.3203C-6.1189 9.34949 -5.16242 7.48981 -3.56177 6.34365L12.2945 -4.98118C13.3381 -5.72625 14.5678 -6.11982 15.8486 -6.11982C17.1295 -6.11982 18.3577 -5.72625 19.4013 -4.98268L43.1782 12.0008C44.7833 13.15 45.7413 15.0112 45.7413 16.982C45.7413 18.9528 44.7848 20.8125 43.1842 21.9587L27.3309 33.2835C26.2858 34.0286 25.0576 34.4221 23.7768 34.4221H23.7738Z'
						fill={IS_BLUE_BACKGROUND ? '#0058D5' : '#1989aa'}
					/>
					<path
						d='M23.7738 34.2824C22.523 34.2824 21.3218 33.8979 20.3022 33.1708L-3.47468 16.1874C-5.04379 15.0637 -5.97925 13.2461 -5.97925 11.3203C-5.97925 9.39455 -5.04529 7.57693 -3.47918 6.45632L12.3756 -4.86701C13.3966 -5.59556 14.5964 -5.98012 15.8486 -5.98012C17.1009 -5.98012 18.3007 -5.59556 19.3202 -4.86851L43.0971 12.115C44.6662 13.2371 45.6017 15.0562 45.6017 16.982C45.6017 18.9078 44.6677 20.7254 43.1016 21.846L27.2483 33.1708C26.2273 33.8994 25.0276 34.2839 23.7753 34.2839L23.7738 34.2824Z'
						fill={IS_BLUE_BACKGROUND ? '#0057D3' : '#1988a9'}
					/>
					<path
						d='M23.7738 34.1427C22.5515 34.1427 21.3788 33.7672 20.3833 33.0567L-3.39359 16.0732C-4.92666 14.9751 -5.8396 13.1995 -5.8396 11.3203C-5.8396 9.44112 -4.92666 7.66406 -3.3981 6.57048L12.4567 -4.75285C13.4537 -5.46487 14.6264 -5.84042 15.8486 -5.84042C17.0709 -5.84042 18.2436 -5.46487 19.2391 -4.75435L43.0145 12.2291C44.5476 13.3257 45.4605 15.1013 45.4605 16.982C45.4605 18.8627 44.5476 20.6383 43.019 21.7318L27.1658 33.0567C26.1687 33.7687 24.996 34.1442 23.7738 34.1442V34.1427Z'
						fill={IS_BLUE_BACKGROUND ? '#0056D2' : '#1887a7'}
					/>
					<path
						d='M23.7738 34.003C22.5816 34.003 21.4374 33.6365 20.4644 32.944L-3.3125 15.9605C-4.80954 14.8895 -5.69995 13.156 -5.69995 11.3203C-5.69995 9.48469 -4.80954 7.75269 -3.31701 6.68465L12.5378 -4.63868C13.5108 -5.33268 14.6549 -5.69921 15.8487 -5.69921C17.0424 -5.69921 18.185 -5.33268 19.158 -4.64018L42.9334 12.3433C44.429 13.4129 45.3209 15.1464 45.3209 16.9835C45.3209 18.8207 44.4305 20.5511 42.9379 21.6192L27.0847 32.944C26.1117 33.638 24.9675 34.0061 23.7738 34.0061V34.003Z'
						fill={IS_BLUE_BACKGROUND ? '#0056D0' : '#1886a6'}
					/>
					<path
						d='M23.7737 33.8633C22.6101 33.8633 21.4944 33.5058 20.5454 32.8299L-3.22995 15.8464C-4.68945 14.8009 -5.55884 13.1109 -5.55884 11.3203C-5.55884 9.52975 -4.68945 7.83981 -3.23445 6.79731L12.6188 -4.52602C13.5678 -5.2035 14.6849 -5.56101 15.8486 -5.56101C17.0123 -5.56101 18.128 -5.2035 19.0769 -4.52752L42.8523 12.456C44.3118 13.5 45.1812 15.1914 45.1812 16.982C45.1812 18.7726 44.3118 20.4625 42.8568 21.505L27.0036 32.8284C26.0546 33.5058 24.9374 33.8633 23.7737 33.8633V33.8633Z'
						fill={IS_BLUE_BACKGROUND ? '#0055CE' : '#1884a4'}
					/>
					<path
						d='M23.7738 33.7236C22.6401 33.7236 21.5515 33.3751 20.6265 32.7157L-3.14886 15.7322C-4.57232 14.7137 -5.41919 13.0659 -5.41919 11.3203C-5.41919 9.57482 -4.57232 7.92694 -3.15337 6.91148L12.7014 -4.41185C13.6263 -5.07281 14.715 -5.42131 15.8501 -5.42131C16.9853 -5.42131 18.0724 -5.07281 18.9974 -4.41336L42.7727 12.5701C44.1947 13.5871 45.0431 15.2365 45.0431 16.982C45.0431 18.7275 44.1962 20.3754 42.7772 21.3909L26.924 32.7142C25.999 33.3751 24.9104 33.7236 23.7753 33.7236H23.7738Z'
						fill={IS_BLUE_BACKGROUND ? '#0055CC' : '#1883a3'}
					/>
					<path
						d='M23.7738 33.5839C22.6686 33.5839 21.6085 33.2445 20.7076 32.603L-3.06777 15.6195C-4.45369 14.6266 -5.27954 13.0208 -5.27954 11.3218C-5.27954 9.62289 -4.45369 8.01557 -3.07228 7.02714L12.7825 -4.29769C13.6834 -4.94062 14.745 -5.28161 15.8501 -5.28161C16.9553 -5.28161 18.0154 -4.94212 18.9163 -4.30069L42.6917 12.6828C44.0776 13.6742 44.9034 15.28 44.9034 16.9805C44.9034 18.6809 44.0776 20.2868 42.6962 21.2752L26.8429 32.5985C25.942 33.2414 24.8804 33.5824 23.7753 33.5824L23.7738 33.5839Z'
						fill={IS_BLUE_BACKGROUND ? '#0054CA' : '#1882a1'}
					/>
					<path
						d='M23.7738 33.4442C22.6972 33.4442 21.6656 33.1138 20.7887 32.4889L-2.98668 15.5054C-4.33657 14.5395 -5.13989 12.9757 -5.13989 11.3203C-5.13989 9.66495 -4.33657 8.10119 -2.99119 7.1383L12.8636 -4.18503C13.7405 -4.81143 14.7735 -5.14191 15.8501 -5.14191C16.9267 -5.14191 17.9583 -4.81143 18.8352 -4.18653L42.6106 12.797C43.9605 13.7629 44.7638 15.3251 44.7638 16.982C44.7638 18.6389 43.9605 20.2011 42.6151 21.164L26.7618 32.4874C25.8849 33.1138 24.8519 33.4442 23.7753 33.4442H23.7738Z'
						fill={IS_BLUE_BACKGROUND ? '#0053C8' : '#1781a0'}
					/>
					<path
						d='M23.7738 33.3045C22.7272 33.3045 21.7227 32.9831 20.8698 32.3747L-2.9056 15.3912C-4.21794 14.4508 -5.00024 12.9307 -5.00024 11.3203C-5.00024 9.71001 -4.21944 8.18981 -2.9101 7.25246L12.9447 -4.07087C13.799 -4.68075 14.8021 -5.00221 15.8501 -5.00221C16.8982 -5.00221 17.9012 -4.68075 18.7541 -4.07237L42.5295 12.9111C43.8419 13.85 44.6242 15.3717 44.6242 16.982C44.6242 18.5923 43.8434 20.1125 42.534 21.0499L26.6808 32.3732C25.8264 32.9831 24.8218 33.3045 23.7753 33.3045H23.7738Z'
						fill={IS_BLUE_BACKGROUND ? '#0053C6' : '#17809f'}
					/>
					<path
						d='M23.7737 33.1648C22.7557 33.1648 21.7797 32.8524 20.9523 32.2605L-2.82304 15.277C-4.09935 14.3637 -4.85913 12.8856 -4.85913 11.3203C-4.85913 9.75508 -4.09935 8.27695 -2.82755 7.36513L13.0257 -3.9567C13.8561 -4.54855 14.8321 -4.8625 15.8501 -4.8625C16.8682 -4.8625 17.8442 -4.55005 18.6715 -3.9582L42.4469 13.0253C43.7232 13.9386 44.483 15.4167 44.483 16.982C44.483 18.5473 43.7232 20.0254 42.4514 20.9372L26.5981 32.2605C25.7678 32.8524 24.7918 33.1663 23.7737 33.1663V33.1648Z'
						fill={IS_BLUE_BACKGROUND ? '#0052C4' : '#177f9d'}
					/>
					<path
						d='M23.7737 33.0251C22.7857 33.0251 21.8383 32.7217 21.0334 32.1479L-2.74195 15.1644C-3.98223 14.2766 -4.71948 12.8405 -4.71948 11.3203C-4.71948 9.80014 -3.98223 8.36406 -2.74496 7.47929L13.1068 -3.84404C13.9131 -4.41937 14.8606 -4.72281 15.8501 -4.72281C16.8396 -4.72281 17.7856 -4.41937 18.5904 -3.84554L42.3658 13.1379C43.6046 14.0242 44.3433 15.4603 44.3433 16.982C44.3433 18.5037 43.6061 19.9383 42.3688 20.823L26.5156 32.1464C25.7092 32.7217 24.7618 33.0251 23.7722 33.0251H23.7737Z'
						fill={IS_BLUE_BACKGROUND ? '#0051C2' : '#177e9c'}
					/>
					<path
						d='M23.7737 32.8854C22.8143 32.8854 21.8953 32.591 21.1145 32.0337L-2.66087 15.0502C-3.8636 14.1895 -4.57983 12.7955 -4.57983 11.3203C-4.57983 9.8452 -3.8636 8.45119 -2.66387 7.59345L13.1894 -3.72988C13.9717 -4.28868 14.8921 -4.58311 15.8516 -4.58311C16.8111 -4.58311 17.7301 -4.28868 18.5109 -3.73138L42.2862 13.2521C43.489 14.1129 44.2052 15.5054 44.2052 16.982C44.2052 18.4586 43.489 19.8511 42.2892 20.7089L26.436 32.0322C25.6537 32.591 24.7332 32.8854 23.7737 32.8854Z'
						fill={IS_BLUE_BACKGROUND ? '#0051C1' : '#177d9a'}
					/>
					<path
						d='M23.7738 32.7457C22.8443 32.7457 21.9524 32.4603 21.1956 31.9195L-2.57978 14.936C-3.74647 14.1008 -4.44019 12.7504 -4.44019 11.3203C-4.44019 9.89027 -3.74647 8.53982 -2.58278 7.70612L13.2705 -3.61721C14.0288 -4.15799 14.9207 -4.44491 15.8516 -4.44491C16.7826 -4.44491 17.673 -4.1595 18.4298 -3.61872L42.2052 13.3648C43.3719 14.1985 44.0656 15.5504 44.0656 16.9805C44.0656 18.4106 43.3719 19.761 42.2082 20.5947L26.3549 31.918C25.5966 32.4588 24.7047 32.7457 23.7738 32.7457Z'
						fill={IS_BLUE_BACKGROUND ? '#0050BF' : '#167c99'}
					/>
					<path
						d='M23.7738 32.606C22.8728 32.606 22.0094 32.3296 21.2767 31.8069L-2.49869 14.8249C-3.62785 14.0167 -4.30054 12.7083 -4.30054 11.3218C-4.30054 9.93533 -3.62785 8.62845 -2.50169 7.82178L13.3516 -3.50155C14.0858 -4.0258 14.9507 -4.3022 15.8516 -4.3022C16.7526 -4.3022 17.6159 -4.0258 18.3487 -3.50305L42.1241 13.4804C43.2532 14.2886 43.9259 15.597 43.9259 16.9835C43.9259 18.37 43.2532 19.6769 42.1271 20.4835L26.2753 31.8069C25.5411 32.3311 24.6762 32.6075 23.7753 32.6075L23.7738 32.606Z'
						fill={IS_BLUE_BACKGROUND ? '#004FBD' : '#167b97'}
					/>
					<path
						d='M23.7738 32.4663C22.9029 32.4663 22.0665 32.1989 21.3578 31.6927L-2.4176 14.7107C-3.51072 13.9281 -4.16089 12.6618 -4.16089 11.3218C-4.16089 9.9819 -3.51072 8.71557 -2.4206 7.93595L13.4312 -3.38738C14.1414 -3.89511 14.9777 -4.1625 15.8501 -4.1625C16.7225 -4.1625 17.5574 -3.89511 18.2661 -3.38888L42.0415 13.5946C43.1346 14.3757 43.7848 15.6421 43.7848 16.9835C43.7848 18.3249 43.1346 19.5898 42.0445 20.3694L26.1927 31.6927C25.4825 32.2004 24.6462 32.4678 23.7738 32.4678V32.4663Z'
						fill={IS_BLUE_BACKGROUND ? '#004FBB' : '#167a96'}
					/>
					<path
						d='M23.7738 32.3266C22.9314 32.3266 22.1236 32.0683 21.4389 31.5785L-2.33651 14.5966C-3.3936 13.841 -4.02124 12.6167 -4.02124 11.3218C-4.02124 10.027 -3.39209 8.8027 -2.33952 8.04861L13.5122 -3.27472C14.1984 -3.76442 15.0078 -4.0243 15.8501 -4.0243C16.6925 -4.0243 17.5003 -3.76593 18.185 -3.27622L41.9604 13.7058C43.016 14.4614 43.6452 15.6841 43.6452 16.9805C43.6452 18.2769 43.016 19.4996 41.9634 20.2537L26.1117 31.577C25.4255 32.0667 24.6161 32.3266 23.7738 32.3266V32.3266Z'
						fill={IS_BLUE_BACKGROUND ? '#004EB9' : '#167994'}
					/>
					<path
						d='M23.7738 32.1869C22.9614 32.1869 22.1821 31.9376 21.52 31.4644L-2.25542 14.4824C-3.27497 13.7523 -3.88159 12.5716 -3.88159 11.3203C-3.88159 10.069 -3.27497 8.88832 -2.25843 8.16127L13.5933 -3.16206C14.257 -3.63524 15.0363 -3.8846 15.8501 -3.8846C16.664 -3.8846 17.4433 -3.63524 18.104 -3.16206L41.8793 13.8199C42.8989 14.5485 43.5055 15.7307 43.5055 16.982C43.5055 18.2333 42.8989 19.414 41.8823 20.1411L26.0306 31.4644C25.3669 31.9376 24.5876 32.1869 23.7738 32.1869V32.1869Z'
						fill={IS_BLUE_BACKGROUND ? '#004DB7' : '#167893'}
					/>
					<path
						d='M23.7738 32.0472C22.99 32.0472 22.2392 31.8069 21.6011 31.3517L-2.17434 14.3697C-3.15784 13.6667 -3.74194 12.5281 -3.74194 11.3218C-3.74194 10.1156 -3.15634 8.97845 -2.17734 8.27694L13.6744 -3.04639C14.3141 -3.50305 15.0663 -3.74339 15.8502 -3.74339C16.634 -3.74339 17.3847 -3.50305 18.0229 -3.04789L41.7983 13.9341C42.7803 14.6371 43.3659 15.7758 43.3659 16.982C43.3659 18.1882 42.7803 19.3254 41.8013 20.0269L25.9495 31.3502C25.3099 31.8069 24.5576 32.0472 23.7738 32.0472Z'
						fill={IS_BLUE_BACKGROUND ? '#004DB5' : '#157792'}
					/>
					<path
						d='M23.7738 31.9075C23.02 31.9075 22.2963 31.6762 21.6821 31.2376L-2.09325 14.2556C-3.03922 13.5781 -3.60229 12.483 -3.60229 11.3218C-3.60229 10.1607 -3.03922 9.06558 -2.09625 8.3896L13.7555 -2.93373C14.3711 -3.37236 15.0949 -3.60519 15.8502 -3.60519C16.6054 -3.60519 17.3277 -3.37386 17.9418 -2.93523L41.7172 14.0468C42.6632 14.7227 43.2262 15.8193 43.2262 16.9805C43.2262 18.1417 42.6632 19.2367 41.7202 19.9127L25.8684 31.2361C25.2528 31.6747 24.5291 31.9075 23.7738 31.9075V31.9075Z'
						fill={IS_BLUE_BACKGROUND ? '#004CB3' : '#157690'}
					/>
					<path
						d='M23.7738 31.7678C23.0485 31.7678 22.3533 31.5455 21.7632 31.1234L-2.01216 14.1414C-2.92209 13.491 -3.46265 12.4364 -3.46265 11.3218C-3.46265 10.2072 -2.92059 9.15271 -2.01366 8.50377L13.8381 -2.81956C14.4297 -3.24167 15.1249 -3.46399 15.8502 -3.46399C16.5754 -3.46399 17.2706 -3.24167 17.8607 -2.81956L41.6361 14.1624C42.546 14.8129 43.0866 15.8659 43.0866 16.982C43.0866 18.0981 42.5445 19.1511 41.6376 19.8001L25.7859 31.1234C25.1942 31.5455 24.499 31.7678 23.7738 31.7678V31.7678Z'
						fill={IS_BLUE_BACKGROUND ? '#004CB1' : '#15758f'}
					/>
					<path
						d='M23.7738 31.6281C23.0786 31.6281 22.4104 31.4148 21.8443 31.0107L-1.93107 14.0287C-2.80347 13.4038 -3.323 12.3929 -3.323 11.3218C-3.323 10.2508 -2.80347 9.23983 -1.93257 8.61794L13.9192 -2.70539C14.4868 -3.11098 15.155 -3.32429 15.8502 -3.32429C16.5454 -3.32429 17.2136 -3.11098 17.7796 -2.7069L41.555 14.2751C42.4274 14.9 42.947 15.911 42.947 16.982C42.947 18.053 42.4274 19.064 41.5565 19.6859L25.7048 31.0092C25.1372 31.4148 24.469 31.6281 23.7738 31.6281V31.6281Z'
						fill={IS_BLUE_BACKGROUND ? '#004BB0' : '#15738d'}
					/>
					<path
						d='M23.7738 31.4884C23.1071 31.4884 22.4675 31.2841 21.9254 30.8966L-1.84998 13.9146C-2.68634 13.3152 -3.18335 12.3478 -3.18335 11.3218C-3.18335 10.2959 -2.68484 9.32696 -1.85148 8.7306L14.0003 -2.59273C14.5438 -2.98029 15.1835 -3.18609 15.8502 -3.18609C16.5169 -3.18609 17.1565 -2.98179 17.6986 -2.59424L41.474 14.3878C42.3103 14.9856 42.8073 15.9545 42.8073 16.9805C42.8073 18.0065 42.3088 18.9754 41.4755 19.5717L25.6237 30.8951C25.0801 31.2826 24.4405 31.4884 23.7738 31.4884V31.4884Z'
						fill={IS_BLUE_BACKGROUND ? '#004AAE' : '#15728c'}
					/>
					<path
						d='M23.7738 31.3487C23.1372 31.3487 22.5245 31.1534 22.0065 30.7824L-1.76889 13.8004C-2.56921 13.2281 -3.0437 12.3028 -3.0437 11.3218C-3.0437 10.3409 -2.56771 9.41559 -1.7704 8.84476L14.0814 -2.47857C14.6009 -2.8496 15.2135 -3.04488 15.8502 -3.04488C16.4868 -3.04488 17.0995 -2.8496 17.6175 -2.47857L41.3929 14.5034C42.1917 15.0757 42.6677 16.0011 42.6677 16.982C42.6677 17.9629 42.1917 18.8882 41.3944 19.4591L25.5426 30.7824C25.0231 31.1534 24.4105 31.3502 23.7738 31.3502V31.3487Z'
						fill={IS_BLUE_BACKGROUND ? '#004AAC' : '#14718a'}
					/>
					<path
						d='M23.7738 31.209C23.1657 31.209 22.5831 31.0227 22.0876 30.6682L-1.68777 13.6862C-2.45055 13.1395 -2.90552 12.2562 -2.90552 11.3203C-2.90552 10.3845 -2.45205 9.50121 -1.69077 8.95592L14.161 -2.36741C14.6565 -2.72192 15.2406 -2.90819 15.8487 -2.90819C16.4568 -2.90819 17.0394 -2.72192 17.5349 -2.36741L41.3103 14.6146C42.0731 15.1599 42.5281 16.0431 42.5281 16.9805C42.5281 17.9178 42.0746 18.7996 41.3133 19.3449L25.4616 30.6667C24.9661 31.0212 24.382 31.2075 23.7738 31.2075V31.209Z'
						fill={IS_BLUE_BACKGROUND ? '#0049AA' : '#147089'}
					/>
					<path
						d='M23.7739 31.0693C23.1958 31.0693 22.6402 30.8921 22.1687 30.5556L-1.60668 13.5736C-2.33343 13.0538 -2.76587 12.2126 -2.76587 11.3218C-2.76587 10.4311 -2.33343 9.58984 -1.60968 9.07159L14.2421 -2.25024C14.7151 -2.58672 15.2706 -2.76548 15.8487 -2.76548C16.4268 -2.76548 16.9824 -2.58822 17.4539 -2.25174L41.2293 14.7303C41.956 15.25 42.3884 16.0912 42.3884 16.982C42.3884 17.8728 41.956 18.714 41.2323 19.2322L25.3805 30.5541C24.9075 30.8921 24.3519 31.0693 23.7739 31.0693Z'
						fill={IS_BLUE_BACKGROUND ? '#0048A8' : '#146f88'}
					/>
					<path
						d='M23.7739 30.9296C23.2243 30.9296 22.6973 30.7614 22.2498 30.4414L-1.52559 13.4594C-2.2148 12.9652 -2.62622 12.1676 -2.62622 11.3218C-2.62622 10.4761 -2.2163 9.67696 -1.52859 9.18576L14.3232 -2.13607C14.7721 -2.45603 15.2992 -2.62578 15.8487 -2.62578C16.3983 -2.62578 16.9253 -2.45754 17.3728 -2.13757L41.1482 14.8444C41.8374 15.3371 42.2488 16.1363 42.2488 16.982C42.2488 17.8277 41.8389 18.6269 41.1512 19.1181L25.2994 30.4399C24.8505 30.7599 24.3234 30.9296 23.7739 30.9296Z'
						fill={IS_BLUE_BACKGROUND ? '#0048A6' : '#146e86'}
					/>
					<path
						d='M23.7739 30.7899C23.2543 30.7899 22.7543 30.6307 22.3309 30.3272L-1.4445 13.3453C-2.09767 12.8781 -2.48657 12.1225 -2.48657 11.3203C-2.48657 10.5182 -2.09767 9.76409 -1.44751 9.29692L14.4043 -2.02491C14.8292 -2.32835 15.3292 -2.48758 15.8487 -2.48758C16.3683 -2.48758 16.8683 -2.32835 17.2917 -2.02491L41.0656 14.9571C41.7188 15.4243 42.1077 16.1798 42.1077 16.982C42.1077 17.7842 41.7188 18.5382 41.0686 19.0054L25.2183 30.3272C24.7934 30.6307 24.2934 30.7899 23.7739 30.7899Z'
						fill={IS_BLUE_BACKGROUND ? '#0047A4' : '#146d85'}
					/>
					<path
						d='M23.7739 30.6502C23.2829 30.6502 22.8114 30.5 22.412 30.2146L-1.36342 13.2326C-1.97905 12.791 -2.34692 12.0774 -2.34692 11.3218C-2.34692 10.5662 -1.98055 9.85272 -1.36642 9.41258L14.4838 -1.90924C14.8848 -2.19616 15.3562 -2.34637 15.8472 -2.34637C16.3382 -2.34637 16.8097 -2.19616 17.2091 -1.91075L40.983 15.0712C41.5987 15.5114 41.9665 16.2264 41.9665 16.982C41.9665 17.7376 41.6002 18.4511 40.986 18.8913L25.1358 30.2131C24.7349 30.5 24.2634 30.6502 23.7724 30.6502H23.7739Z'
						fill={IS_BLUE_BACKGROUND ? '#0046A2' : '#136c83'}
					/>
					<path
						d='M23.7738 30.5105C23.3129 30.5105 22.8684 30.3693 22.493 30.1004L-1.28086 13.1184C-1.86046 12.7038 -2.20581 12.0324 -2.20581 11.3218C-2.20581 10.6113 -1.86046 9.93984 -1.28236 9.52674L14.5679 -1.79509C14.9448 -2.06397 15.3877 -2.20668 15.8502 -2.20668C16.3127 -2.20668 16.7556 -2.06548 17.131 -1.79659L40.9049 15.1854C41.4845 15.6 41.8299 16.2715 41.8299 16.982C41.8299 17.6925 41.4845 18.364 40.9064 18.7771L25.0562 30.0989C24.6793 30.3678 24.2363 30.5105 23.7738 30.5105V30.5105Z'
						fill={IS_BLUE_BACKGROUND ? '#0046A0' : '#136b82'}
					/>
					<path
						d='M23.7738 30.3708C23.3414 30.3708 22.9255 30.2386 22.5741 29.9862L-1.19977 13.0043C-1.74333 12.6152 -2.06616 11.9873 -2.06616 11.3218C-2.06616 10.6564 -1.74333 10.027 -1.20127 9.6394L14.649 -1.68242C15.0018 -1.93479 15.4178 -2.06698 15.8502 -2.06698C16.2827 -2.06698 16.6986 -1.93479 17.0499 -1.68242L40.8238 15.2996C41.3659 15.6871 41.6902 16.3165 41.6902 16.982C41.6902 17.6475 41.3674 18.2769 40.8253 18.6644L24.9751 29.9862C24.6222 30.2386 24.2063 30.3708 23.7738 30.3708Z'
						fill={IS_BLUE_BACKGROUND ? '#00459E' : '#136a80'}
					/>
					<path
						d='M23.7738 30.2311C23.3714 30.2311 22.984 30.1079 22.6552 29.8721L-1.11869 12.8901C-1.6247 12.5281 -1.92651 11.9422 -1.92651 11.3203C-1.92651 10.6984 -1.6247 10.1126 -1.12019 9.75207L14.7301 -1.56976C15.0589 -1.8056 15.4463 -1.92878 15.8502 -1.92878C16.2541 -1.92878 16.64 -1.8056 16.9689 -1.56976L40.7428 15.4122C41.2488 15.7743 41.5506 16.3601 41.5506 16.982C41.5506 17.6039 41.2488 18.1897 40.7443 18.5503L24.894 29.8721C24.5652 30.1079 24.1778 30.2311 23.7738 30.2311Z'
						fill={IS_BLUE_BACKGROUND ? '#00449D' : '#13697f'}
					/>
					<path
						d='M23.7739 30.0914C23.4 30.0914 23.0411 29.9772 22.7363 29.7594L-1.0376 12.7774C-1.50758 12.4409 -1.78687 11.8972 -1.78687 11.3218C-1.78687 10.7465 -1.50758 10.2027 -1.0391 9.86773L14.8112 -1.45409C15.116 -1.67191 15.4763 -1.78757 15.8502 -1.78757C16.2241 -1.78757 16.583 -1.67341 16.8878 -1.4556L40.6617 15.5264C41.1317 15.8629 41.4109 16.4067 41.4109 16.982C41.4109 17.5573 41.1317 18.1011 40.6632 18.4361L24.8129 29.7579C24.5081 29.9757 24.1477 30.0914 23.7739 30.0914Z'
						fill={IS_BLUE_BACKGROUND ? '#00449B' : '#13687e'}
					/>
					<path
						d='M23.7739 29.9517C23.4285 29.9517 23.0982 29.8465 22.8174 29.6453L-0.956508 12.6633C-1.38895 12.3538 -1.64722 11.8521 -1.64722 11.3218C-1.64722 10.7916 -1.38895 10.2898 -0.95801 9.9804L14.8922 -1.34143C15.1745 -1.54272 15.5049 -1.64787 15.8502 -1.64787C16.1956 -1.64787 16.5259 -1.54272 16.8067 -1.34143L40.5806 15.6406C41.013 15.95 41.2713 16.4517 41.2713 16.982C41.2713 17.5123 41.013 18.014 40.5821 18.3234L24.7318 29.6453C24.4496 29.8465 24.1192 29.9517 23.7739 29.9517V29.9517Z'
						fill={IS_BLUE_BACKGROUND ? '#004399' : '#12677c'}
					/>
					<path
						d='M23.7739 29.812C23.4585 29.812 23.1552 29.7159 22.8985 29.5311L-0.87542 12.5491C-1.27183 12.2652 -1.50757 11.807 -1.50757 11.3203C-1.50757 10.8336 -1.27183 10.3755 -0.876921 10.0931L14.9733 -1.22877C15.2316 -1.41354 15.5349 -1.50967 15.8502 -1.50967C16.1656 -1.50967 16.4689 -1.41354 16.7256 -1.22877L40.4995 15.7532C40.8959 16.0371 41.1317 16.4953 41.1317 16.982C41.1317 17.4687 40.8959 17.9269 40.501 18.2093L24.6508 29.5311C24.3925 29.7159 24.0892 29.812 23.7739 29.812Z'
						fill={IS_BLUE_BACKGROUND ? '#004297' : '#12667b'}
					/>
					<path
						d='M23.7739 29.6723C23.4871 29.6723 23.2123 29.5852 22.9796 29.4184L-0.794332 12.4364C-1.1532 12.1796 -1.36792 11.7635 -1.36792 11.3218C-1.36792 10.8802 -1.1532 10.4641 -0.795833 10.2072L15.0544 -1.1146C15.2887 -1.28134 15.5634 -1.36997 15.8502 -1.36997C16.137 -1.36997 16.4118 -1.28285 16.6446 -1.11611L40.4184 15.8659C40.7773 16.1228 40.992 16.5389 40.992 16.9805C40.992 17.4221 40.7773 17.8382 40.4199 18.0951L24.5697 29.4169C24.3354 29.5837 24.0607 29.6723 23.7739 29.6723Z'
						fill={IS_BLUE_BACKGROUND ? '#004295' : '#126579'}
					/>
					<path
						d='M23.7739 29.5326C23.5171 29.5326 23.2694 29.4545 23.0606 29.3043L-0.713243 12.3223C-1.03607 12.0909 -1.22827 11.7169 -1.22827 11.3218C-1.22827 10.9268 -1.03607 10.5527 -0.714745 10.3214L15.1355 -1.00044C15.3457 -1.15065 15.5935 -1.22877 15.8502 -1.22877C16.107 -1.22877 16.3548 -1.15065 16.5635 -1.00044L40.3374 15.9816C40.6602 16.2129 40.8524 16.5869 40.8524 16.982C40.8524 17.3771 40.6602 17.7511 40.3389 17.9824L24.4886 29.3043C24.2784 29.4545 24.0306 29.5326 23.7739 29.5326Z'
						fill={IS_BLUE_BACKGROUND ? '#004193' : '#126478'}
					/>
					<path
						d='M23.7739 29.3929C23.5456 29.3929 23.3279 29.3223 23.1417 29.1901L-0.632155 12.2081C-0.918949 12.0038 -1.08862 11.6718 -1.08862 11.3203C-1.08862 10.9688 -0.918949 10.6383 -0.633656 10.4341L15.2166 -0.887776C15.4028 -1.02147 15.622 -1.09057 15.8503 -1.09057C16.0785 -1.09057 16.2962 -1.02147 16.4824 -0.887776L40.2563 16.0942C40.5431 16.2985 40.7128 16.6305 40.7128 16.982C40.7128 17.3335 40.5431 17.664 40.2578 17.8683L24.4075 29.1901C24.2213 29.3238 24.0021 29.3929 23.7739 29.3929Z'
						fill={IS_BLUE_BACKGROUND ? '#004191' : '#126276'}
					/>
					<path
						d='M23.7739 29.2532C23.5757 29.2532 23.385 29.1916 23.2228 29.0759L-0.551066 12.0939C-0.800322 11.9152 -0.948975 11.6268 -0.948975 11.3203C-0.948975 11.0139 -0.800322 10.7255 -0.551066 10.5467L15.2992 -0.775114C15.4614 -0.890781 15.6521 -0.95237 15.8518 -0.95237C16.0515 -0.95237 16.2407 -0.890781 16.4028 -0.775114L40.1767 16.2069C40.426 16.3856 40.5746 16.674 40.5746 16.9805C40.5746 17.2869 40.426 17.5754 40.1767 17.7541L24.3265 29.0759C24.1643 29.1916 23.9736 29.2532 23.7739 29.2532Z'
						fill={IS_BLUE_BACKGROUND ? '#00408F' : '#116175'}
					/>
					<path
						d='M23.7739 29.1135C23.6042 29.1135 23.442 29.0609 23.3039 28.9633L-0.469978 11.9813C-0.683197 11.8296 -0.809326 11.5832 -0.809326 11.3218C-0.809326 11.0605 -0.683197 10.8141 -0.469978 10.6624L15.3803 -0.659445C15.5184 -0.758588 15.6821 -0.809662 15.8518 -0.809662C16.0214 -0.809662 16.1836 -0.757086 16.3217 -0.659445L40.0956 16.3225C40.3088 16.4743 40.435 16.7206 40.435 16.982C40.435 17.2434 40.3088 17.4897 40.0956 17.6414L24.2454 28.9633C24.1072 29.0624 23.9436 29.1135 23.7739 29.1135Z'
						fill={IS_BLUE_BACKGROUND ? '#003F8D' : '#116074'}
					/>
					<path
						d='M23.7739 28.9738C23.6343 28.9738 23.4991 28.9302 23.385 28.8491L-0.38889 11.8671C-0.56457 11.7409 -0.669678 11.5366 -0.669678 11.3203C-0.669678 11.104 -0.56457 10.8997 -0.38889 10.775L15.4614 -0.546783C15.5755 -0.629402 15.7106 -0.671463 15.8518 -0.671463C15.9929 -0.671463 16.1266 -0.6279 16.2407 -0.546783L40.0146 16.4352C40.1902 16.5614 40.2953 16.7657 40.2953 16.982C40.2953 17.1983 40.1902 17.4026 40.0146 17.5273L24.1658 28.8491C24.0517 28.9317 23.9165 28.9738 23.7754 28.9738H23.7739Z'
						fill={IS_BLUE_BACKGROUND ? '#003F8C' : '#115f72'}
					/>
					<path
						d='M23.7739 28.8341C23.6628 28.8341 23.5562 28.7995 23.4661 28.7349L-0.307801 11.753C-0.447445 11.6538 -0.530029 11.4916 -0.530029 11.3203C-0.530029 11.1491 -0.447445 10.9884 -0.307801 10.8877L15.541 -0.434121C15.6325 -0.498714 15.7392 -0.533264 15.8503 -0.533264C15.9614 -0.533264 16.068 -0.498714 16.1581 -0.434121L39.932 16.5479C40.0716 16.647 40.1542 16.8092 40.1542 16.9805C40.1542 17.1517 40.0716 17.3125 39.932 17.4131L24.0832 28.7349C23.9916 28.7995 23.885 28.8341 23.7739 28.8341Z'
						fill={IS_BLUE_BACKGROUND ? '#003E8A' : '#115e71'}
					/>
					<path
						d='M23.7739 28.6944C23.6928 28.6944 23.6132 28.6689 23.5472 28.6223L-0.226713 11.6403C-0.328818 11.5667 -0.390381 11.448 -0.390381 11.3218C-0.390381 11.1957 -0.328818 11.077 -0.226713 11.0034L15.622 -0.318453C15.6896 -0.366523 15.7677 -0.392059 15.8488 -0.392059C15.9299 -0.392059 16.0094 -0.366522 16.0755 -0.319955L39.8494 16.662C39.9515 16.7356 40.0131 16.8543 40.0131 16.9805C40.0131 17.1067 39.9515 17.2253 39.8494 17.299L24.0006 28.6208C23.9331 28.6689 23.855 28.6944 23.7739 28.6944Z'
						fill={IS_BLUE_BACKGROUND ? '#003D88' : '#115d6f'}
					/>
					<path
						d='M23.7739 28.5547C23.7214 28.5547 23.6703 28.5382 23.6283 28.5081L-0.145625 11.5261C-0.211692 11.4781 -0.250732 11.403 -0.250732 11.3218C-0.250732 11.2407 -0.211692 11.1641 -0.145625 11.1175L15.7031 -0.202784C15.7467 -0.23433 15.7977 -0.249352 15.8488 -0.249352C15.8998 -0.249352 15.9524 -0.232828 15.9944 -0.202784L39.7683 16.7792C39.8344 16.8258 39.8734 16.9024 39.8734 16.9835C39.8734 17.0646 39.8344 17.1412 39.7683 17.1878L23.9196 28.5081C23.876 28.5397 23.825 28.5547 23.7739 28.5547V28.5547Z'
						fill={IS_BLUE_BACKGROUND ? '#003D86' : '#105c6e'}
					/>
					<path
						d='M23.7739 28.415C23.7514 28.415 23.7289 28.4075 23.7093 28.394L-0.0645362 11.412C-0.094567 11.3909 -0.111084 11.3564 -0.111084 11.3203C-0.111084 11.2843 -0.0930655 11.2497 -0.0645362 11.2287L15.7842 -0.0916253C15.8037 -0.105145 15.8263 -0.112656 15.8488 -0.112656C15.8713 -0.112656 15.8938 -0.105145 15.9134 -0.0916253L39.6872 16.8904C39.7173 16.9114 39.7338 16.9459 39.7338 16.982C39.7338 17.018 39.7158 17.0526 39.6872 17.0736L23.8385 28.394C23.819 28.4075 23.7964 28.415 23.7739 28.415Z'
						fill={IS_BLUE_BACKGROUND ? '#003C84' : '#105b6c'}
					/>
				</g>
			}
			<path
				d='M15.8503 0L0 11.3203L23.7739 28.3023L39.6226 16.982L15.8503 0V0Z'
				fill={type}
				transform={loading ? `translate(${0} ${10})` : undefined}
				className={` ${clsx(
					css(loading ? styleSheet.topLayer : undefined),
					css(isHovering ? styleSheet.hovering : undefined),
					css(loading && isLoaded ? styleSheet.loading : undefined)
				)}`}
			/>
		</svg>
	);
};
